.btn-primary {
    border: none !important;
}

.btn-outline-primary:hover {
    background-image: linear-gradient(130deg, #009845 0%, #FFE200 100%);
}

input[type="text"]{
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
}